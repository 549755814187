<template>
  <router-view :user-type="userType" />
</template>
<script>
export default {
  name: 'Users',
  computed: {
    userType() {
      if (this.$route.name === 'vendor-users') {
        return this.$USER;
      }
      return this.$DEPTADMIN;
    },
  },
};
</script>
